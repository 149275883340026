.container {
    width: 75%;
    margin: auto;
}

.title {
    text-align: left;
}

.content {
    text-align: left;
}

.img {
    width: 66%;
    margin: 0 16.5%;
}

.blogNavContainer {
    width: 75%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: white;
}

.prevButton {
    align-self: flex-start;
}

.nextButton {
    align-self: flex-end;
}

.tagList {
    color: grey;
}